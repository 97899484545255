import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const UserDetails = () => {
  
  document.title = "User Details | Fish-Trak - Internal Management Dashboard";
  
  //On mount variables
  const { userId } = useParams();
  const storage = getStorage();
  const navigate = useNavigate();
  const userRef = firebase.firestore().collection('users').doc(userId);
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [fetchingCatches, setFetchingCatches] = useState(true);
  const [fetchingTags, setFetchingTags] = useState(true);
  const [fetchedData, setFetchedData] = useState(false);
  
  //Data variables
  const [userObject, setUserObject] = useState({});
  const [userOnboarding, setUserOnboarding] = useState({});
  const [userCatches, setUserCatches] = useState([]);
  const [userTags, setUserTags] = useState([]);
  
  //Action buttons
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  
  const [deleteModal, setDeleteModal] = useState(false);
  function toggleDeleteModal() {
      
      setDeleteModal(!deleteModal);
      
  }
  
  const disableUser = async () => {
      
      setIsDeleting(true);
              
      const userRef = firebase.firestore().collection('users').doc(userId);
  
          userRef
              .update({
                  active: false
                })
              .then((userResponse) => {
                alert('The user account has been disabled successfully!');
                getUserDetails();
              })
              .catch((error) => {
                setIsDeleting(false);
                alert('Could not disable user. Please try again.');
              });  
  };
  
  const [activateModal, setActivateModal] = useState(false);
  function toggleActivateModal() {

      setActivateModal(!activateModal);
      
  }
  
  const activateUser = async () => {
      
      setIsActivating(true);
              
      const userRef = firebase.firestore().collection('users').doc(userId);
  
          userRef
              .update({
                  active: true
                })
              .then((userResponse) => {
                getUserDetails();
                alert('The user account has been activated successfully!');
              })
              .catch((error) => {
                setIsActivating(false);
                alert('Could not activate user. Please try again.');
              });  
  };
  
  const [editModal, setEditModal] = useState(false);
  function toggleEditModal() {

      setEditModal(!editModal);
  }
  
  const submitUpdateForm = async (event) => {
  
      setIsUploading(true);
      event.preventDefault();
      
      setIsUpdating(true);
      
      if(updatedImage) {
          updateImage(event);
      }
      else {
          updateUser(event);
      }
  
  }
  
  const updateImage = async (event) => {
      
      event.preventDefault();
                  
    const imageFileName = '/user-avatars/' + userId;
    
    const storageRef = ref(storage, imageFileName);
  
    const uploadTask = uploadBytesResumable(storageRef, updatedImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setIsUpdating(false);
          alert('Could not update user avatar. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          updateUser(event, downloadedURL);
        });
      }
    );
  };
  
  const updateUser = async (event, downloadedURL) => {
              
    //Create object of program data
      const userData = {
        updated_at: serverTimestamp(),
      };
      
      //User information      
      if(event.target.first_name.value != userObject.first_name) {
          userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      }  
      if((userObject.last_name) && (event.target.last_name.value != userObject.last_name)) {
          userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      } 
      else if((!userObject.last_name) || (userObject.last_name == '') || (userObject.last_name == null)) {
          userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
      }
      //Contact details
      if(event.target.email_address.value != userObject.email_address) {
          userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
      }  
      if(event.target.mobile_number.value != userObject.mobile_number) {
          userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
      }  
      //Location data
      if(event.target.home_port.value != userObject.home_port) {
          userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
      } 
      if((event.target.home_port_lat.value != userObject.home_port_geo.latitude) || (event.target.home_port_lng.value != userObject.home_port_geo.longitude)) {
          userData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, '')))
      }
      if(event.target.user_country.value != userObject.country_code) {
          userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
      }
     
      //Metric counts 
      if(event.target.catch_count.value != userObject.catch_count) {
          userData['catch_count'] = !!event.target.catch_count.value ? event.target.catch_count.value.replace(/[^0-9]/g, '') : 0;
      } 
      if(event.target.recatch_count.value != userObject.recatch_count) {
          userData['recatch_count'] = !!event.target.recatch_count.value ? event.target.recatch_count.value.replace(/[^0-9]/g, '') : 0;
      } 
      if(event.target.species_count.value != userObject.species_count) {
          userData['species_count'] = !!event.target.species_count.value ? event.target.species_count.value.replace(/[^0-9]/g, '') : 0;
      }         
      //Account Status
      if(event.target.units.value != userObject.metric_system) {
          userData['metric_system'] = JSON.parse(event.target.units.value);
      }
      //Account Status
      if(event.target.active.value != userObject.active) {
          userData['active'] = event.target.active.checked;
      }
      if(event.target.approved.value != userObject.approved) {
          userData['approved'] = event.target.approved.checked;
      }
      if(event.target.onboarded.value != userObject.onboarded) {
          userData['onboarded'] = event.target.onboarded.checked;
      }
      if(event.target.verified.value != userObject.verified) {
          userData['verified'] = event.target.verified.checked;
      }
      
      //Notifications
      if(event.target.email_updates.value != userObject.email_updates) {
          userData['email_updates'] = event.target.email_updates.checked;
      }
      if(event.target.text_messages.value != userObject.text_messages) {
          userData['text_messages'] = event.target.text_messages.checked;
      }
      if(event.target.recatch_alerts.value != userObject.recatch_alerts) {
          userData['recatch_alerts'] = event.target.recatch_alerts.checked;
      }
      if(event.target.push_notifications.value != userObject.push_notifications) {
          userData['push_notifications'] = event.target.push_notifications.checked;
      }
  
      //Persmissions
      if(event.target.admin_access.value != userObject.admin_user) {
          userData['admin_user'] = event.target.admin_access.checked;
      }
      if(event.target.partner_access.checked != userObject.partner_user) {
          userData['partner_user'] = event.target.partner_access.checked;
      }
      
      if(downloadedURL) {
          userData['image_url'] = downloadedURL;
      }
              
      //Create new catch in firestore
      const userRef = firebase.firestore().collection('users').doc(userId);
      userRef
          .update(userData)
          .then((userResponse) => {
            alert('The user has been updated successfully!');
            getUserDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update user entry. Please try again.');
          });  
  };
  
  //Get user details
  const getUserDetails = async () => {
    userRef
    .get()
    .then((userDoc) => {
      const userData = userDoc.data();
      userData.id = userDoc.id;
      const userLastName = !!userData.last_name ? userData.last_name : '';
      userData.full_name = userData.first_name + ' ' + userLastName;
      
      userData.name = userData.first_name + ' ' + userData.last_name;
      userData.email = userData.email_address;
      userData.port = userData.home_port;
      const createdAt = userData && userData.created_at && userData.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
      userData.created = createdAt;
      const updatedAt = userData && userData.updated_at && userData.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
      userData.updated = updatedAt;
      userData.count = userData.catch_count;
      userData.status = userData.active;
      
      userData.geo_latitude = userData.home_port_geo.latitude;
      userData.geo_longitude = userData.home_port_geo.longitude;
      
      
      getUserOnboarding();
      setUserObject(userData);
      setFetchingDetails(false);
      
      setEditModal(false);
      setIsUploading(false);
      setIsUpdating(false);
      setDeleteModal(false);
      setIsDeleting(false);
      setFetchedData(true);
      setActivateModal(false);
      setIsActivating(false);
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get user details. Please try again.');
    });  
  };
  
  const getUserOnboarding = async () => {
        
   firebase
   .firestore()
   .collection('onboarding')
   .where('user', '==', userRef)
   .where('completed', '==', true)
   .limit(1)
   .orderBy('created_at', 'desc')
   .onSnapshot(
        querySnapshot => {
            const newSpeciesList = []
            querySnapshot.forEach(doc => {
                const onboardingItem = doc.data();
                onboardingItem.id = doc.id;
                onboardingItem.link = '../../onboarding/details/' + doc.id; 
                setUserOnboarding(onboardingItem);
            });
        },
        error => {
            console.log(error);
        }
    )
  };
  
  //Get user catches
  const getUserCatches = async () => {
    firebase
     .firestore()
     .collection('catches')
     .where('user', '==', userRef)
     .orderBy('catch_date', 'desc')
     .onSnapshot(
          querySnapshot => {
              const userCatchesArray = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  catchItem.id = doc.id;
                  
                  catchItem.angler = catchItem.user_name;
                  catchItem.species_link = '../../species/details/' + catchItem.fish_species.id; 
                  const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  catchItem.date = catchDate;
                  catchItem.link = '../../catch/details/' + catchItem.id;  
                  catchItem.tag_link = '../../tag/details/' + catchItem.tag.id; 
                  
                  userCatchesArray.push(catchItem);
              });
              setUserCatches(userCatchesArray);
              setFetchingCatches(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
  }
  
  //Get user tags
  const getUserTags = async () => {
    firebase
     .firestore()
     .collection('tags')
     .where('first_angler', '==', userRef)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const userTagsArray = []
              querySnapshot.forEach(doc => {
                  const tagItem = doc.data();
                  tagItem.id = doc.id;
                  const createdAt = tagItem && tagItem.created_at && tagItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.created = createdAt;
                  const updatedAt = tagItem && tagItem.updated_at && tagItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.updated = updatedAt;
                  tagItem.link = '../../tag/details/' + doc.id;  
                  tagItem.program_link = '../../program/details/' + tagItem.tag_program.id;  
                  userTagsArray.push(tagItem);
              });
              setUserTags(userTagsArray);
              setFetchingTags(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
  }
  
  const createTagsList = () => {
       
      // Existing List
       const tagsListOptions = {
           valueNames: ['program_image', 'tag_number', 'program_name', 'created', 'updated', 'catch_count', 'status'],
           pagination: true,
           indexAsync: true,
           searchColumns: ['tag_number', 'program_name'],
           page: 10,
       }; 
       
       new List('connected-tags-list', tagsListOptions);
  };
  
  const createCatchesList = () => {
                  
          const catchListOptions = {
              valueNames: ['image_url', 'name', 'species_name', 'location_string', 'tag_number', 'weight', 'length', 'date', 'type'],
              pagination: true,
              indexAsync: true,
              searchColumns: ['name', 'species_name', 'location_string', 'tag_number'],
              page: 10
          };    
        
        new List('connected-catch-list', catchListOptions);
   };
  
  useEffect(() => {
    if(initialLoad) {
       setInitialLoad(false);
       if(userId) { 
          getUserDetails();
          getUserCatches();
          getUserTags();
        }
     }
     
     if(fetchedData && !fetchingDetails && !fetchingCatches && userObject && userCatches && (userCatches.length > 0) && userTags && (userTags.length > 0)) {
       setFetchedData(false);
       createTagsList();
       createCatchesList();
     }
  });
  
  return (
    <React.Fragment>
      <div className="page-content">
            
      
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
              
                <CardHeader>
                    <h4 className="card-title mb-0">User's Details</h4>
                </CardHeader>
              
                <CardBody>
                
                  <ThreeCircles
                  visible={fetchingDetails}
                  height="100"
                  width="100"
                  color="#4192C3"
                  ariaLabel="three-circles-loading"
                  wrapperClass="justify-content-center"
                  />
                
                  {!fetchingDetails &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={userObject.image_url}
                        alt={userObject.email_address}
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userObject.full_name}</h5>
                        <p className="mb-1">{userObject.email_address}</p>
                        <p className="mb-0">{userObject.mobile_number}</p>
                        <p className="mb-0">{userObject.home_port}</p>
                        <p className="mb-0">
                          {userObject && userObject.active &&
                            <span className="badge badge-soft-success text-uppercase">Active</span>
                          }
                          {userObject && !userObject.active &&
                            <span className="badge badge-soft-danger text-uppercase">Disabled</span>
                          }
                        </p>
                        <p className="mb-0">Catches: <span className="badge badge-soft-success text-uppercase">{userObject.catch_count}</span></p>
                        <p className="mb-0">Recatches: <span className="badge badge-soft-success text-uppercase">{userObject.recatch_count}</span></p>
                        <p className="mb-0">Species: <span className="badge badge-soft-success text-uppercase">{userObject.species_count}</span></p>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                    
                        {userObject.onboarded &&
                        <div className="edit">
                          <a className="btn btn-md btn-success edit-item-btn" href={userOnboarding.link}><i className="mdi mdi-comment-question"></i></a>
                        </div>
                        }
                    
                        <div className="edit">
                            <button className="btn btn-md btn-warning edit-item-btn" data-bs-toggle="modal" onClick={() => toggleEditModal()} data-bs-target="#updateModal"><i className="mdi mdi-square-edit-outline"></i></button>
                        </div>
                        {userObject.active &&
                        <div className="remove">
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteModal()}><i className="mdi mdi-account-off-outline"></i></button>
                        </div>
                        }
                        {!userObject.active &&
                            <div className="edit">
                                <button className="btn btn-md btn-success edit-item-btn" onClick={() => toggleActivateModal()} data-bs-toggle="modal" data-bs-target="#activateModal"><i className="mdi mdi-account-check-outline"></i></button>
                            </div>
                        }
                    </div>
                  </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">User's Tags</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-tags-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Tags..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="tags-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th data-sort="program_image">Image</th>
                                              <th className="sort" data-sort="tag_number">Fish Tag Number</th>
                                              <th className="sort" data-sort="program_name">Program Name</th>
                                              <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                              <th className="sort" data-sort="updated" data-default-order='desc'>Updated Date</th>
                                              <th className="sort" data-sort="catch_count">Catches</th>
                                              <th className="sort" data-sort="status">Status</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                      {userTags && (userTags.length > 0) && userTags.map((tag, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={tag.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="program_image">
                                                  {tag.program_image &&<img className="avatar-sm rounded" alt={tag.tag_number} src={tag.program_image} />}
                                              </td>
                                              <td className="tag_number">#{tag.tag_number}</td>
                                              <td><a href={tag.program_link} className="program_name fw-medium link-primary">{tag.program_name}</a></td>
                                              <td className="created">{tag.created}</td>
                                              <td className="updated">{tag.updated}</td>
                                              <td><span className="catch_count badge badge-soft-success text-uppercase">{tag.catch_count}</span></td>
                                              
                                              <td className="status">
                                              {tag.used &&
                                                  <span className="badge badge-soft-success text-uppercase">Used</span>
                                              }
                                              {!tag.used &&
                                                  <span className="badge badge-soft-danger text-uppercase">Un-Used</span>
                                              }
                                              </td>
                                          </tr>
                                      ))}
                                      
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingTags}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingTags) && fetchedData && !fetchingDetails && (!userTags || userTags.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
                                  
                                          <h5 className="mt-2">No Tags Found!</h5>
                                          <p className="text-muted mb-0">We could not find any tags.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">User's Catches</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-catch-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="connected-catch-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th data-sort="image_url">Image</th>
                                              <th className="sort" data-sort="name">Catch Name</th>
                                              <th className="sort" data-sort="species_name">Species Name</th>
                                              <th className="sort" data-sort="location_string">Catch Location</th>
                                              <th className="sort" data-sort="tag_number">Tag Number</th>
                                              <th className="sort" data-sort="weight">Weight</th>
                                              <th className="sort" data-sort="length">Length</th>
                                              <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                              <th className="sort" data-sort="type">Type</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                          {userCatches && (userCatches.length > 0) && userCatches.map((catchItem, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="image_url">
                                                  {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                              </td>
                                              <td className="name">{catchItem.name}</td>
                                              <td><a href={catchItem.species_link} className="species_name fw-medium link-primary">{catchItem.species_name}</a></td>
                                              <td className="location_string">{catchItem.location_string}</td>
                                              <td><a href={catchItem.tag_link} className="tag_number fw-medium link-primary">#{catchItem.tag_number}</a></td>
                                              <td className="weight">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.weight}
                                                  {catchItem && catchItem.metric_system && <span> KG</span>}
                                                  {catchItem && !catchItem.metric_system && <span> LB</span>}
                                                </span>
                                              </td>
                                              <td className="length">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.length}
                                                  {catchItem && catchItem.metric_system && <span> CM</span>}
                                                  {catchItem && !catchItem.metric_system && <span> IN</span>}
                                                </span>
                                              </td>
                                              <td className="date">{catchItem.date}</td>
                                              <td className="type">
                                                  {catchItem && catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Recatch</span>
                                                  }
                                                  {catchItem && !catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                  }
                                              </td>
                                          </tr>
                                          ))}
                                          
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingCatches}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingCatches) && fetchedData && !fetchingDetails && (!userCatches || userCatches.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
          
                                          <h5 className="mt-2">No Catches Found!</h5>
                                          <p className="text-muted mb-0">We could not find any catches.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
        </Container>
      </div>
      
      
      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
          <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit User </ModalHeader>
          
          {!isUpdating && userObject &&
          <form className="tablelist-form" onSubmit={submitUpdateForm}>
              <ModalBody>
                  <div className="mb-3"><tr>
                      <td>
                      <label htmlFor="firstname-field" className="form-label">First Name*</label>
                      <input type="text" id="firstname-field" name="first_name" className="form-control" placeholder="Richard" required defaultValue={userObject.first_name}/>
                      </td>
                      <td>
                      <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                      <input type="text" id="lastname-field" className="form-control" name="last_name" placeholder="Ryan" required defaultValue={userObject.last_name}/>
                      </td>
                  </tr></div>
                  <div className="mb-3">
                      <label htmlFor="email-field" className="form-label">Email Address*</label>
                      <input type="email" id="email-field" name="email_address" className="form-control" placeholder="rich@fish-trak.com" required defaultValue={userObject.email_address}/>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="mobile-field" className="form-label">Mobile Number*</label>
                      <input type="tel" id="mobile-field" className="form-control" placeholder="+14151234567" name="mobile_number" required defaultValue={userObject.mobile_number}/>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="image-field" className="form-label">User Avatar*</label>
                      <tr>
                      <td><img className="avatar-sm rounded-circle" alt={userObject.name} src={userObject.image_url}/></td>
                      <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                      </tr>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="homeport-field" className="form-label">Home Port Location*</label>
                      <input type="text" id="homeport-field" className="form-control" name="home_port" placeholder="Toms River" required defaultValue={userObject.home_port}/>
                  </div>
                  <div className="mb-3"><tr>
                      <td>
                      <label htmlFor="latitude-field" className="form-label">Home Port Latitude*</label>
                      <input type="number" id="latitude-field" className="form-control" name="home_port_lat" placeholder="Enter Latitude*" required defaultValue={userObject.geo_latitude} />
                      </td>
                      <td>
                      <label htmlFor="longitude-field" className="form-label">Home Port Longitude*</label>
                      <input type="number" id="longitude-field" className="form-control" name="home_port_lng" placeholder="Enter Longitude*" required defaultValue={userObject.geo_longitude} />
                      </td>
                  </tr>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="country-field" className="form-label">User Country*</label>
                      <select className="form-control" data-trigger id="country-field" required name="user_country" defaultValue={userObject.country_code}>
                          <option value="">Select User Country</option>
                          <option value="USA">United States</option>
                          <option value="CAN">Canada</option>
                      </select>
                  </div>
                  
                  <div className="mb-3"><tr>
                      <td>
                      <label htmlFor="catch-field" className="form-label">Catch Count*</label>
                      <input type="number" name="catch_count" id="catch-field" className="form-control" placeholder="Enter Catch Count*" required defaultValue={userObject.catch_count} />
                      </td>
                      <td>
                      <label htmlFor="recatch-field" className="form-label">Recatch Count*</label>
                      <input type="number" id="recatch-field" name="recatch_count" className="form-control" placeholder="Enter Recatch Count*" required defaultValue={userObject.recatch_count} />
                      </td>
                      <td>
                      <label htmlFor="species-field" className="form-label">Species Count*</label>
                      <input type="number" id="species-field" className="form-control" name="species_count" placeholder="Enter Species Count*" required defaultValue={userObject.species_count} />
                      </td>
                  </tr></div>
                  
                  <div className="mb-3">
                      <label htmlFor="units-radio" className="form-label">Units System*</label>
                      <tr>
                      <td>
                          <input type="radio" id="metric" name="units" value="true" defaultChecked={!userObject.metric_system} />
                          <label style={{marginRight: 15, marginLeft: 5}} htmlFor="metric" className="form-label">Metric</label>
                      </td>
                      <td>
                          <input type="radio" id="imperial" name="units" value="false" defaultChecked={userObject.metric_system} />
                          <label style={{marginLeft: 5}} htmlFor="imperial" className="form-label">Imperial</label>
                      </td>
                  </tr></div>
                  
                  <div className="mb-3">
                      <label htmlFor="notifications-checkbox" className="form-label">Account Status*</label>
                      <tr>
                          <td>
                              <input type="checkbox" id="active" name="active" value="true" defaultChecked={userObject.active}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="active"> Activated</label>
                          </td>
                          <td>
                              <input type="checkbox" id="approved" name="approved" value="true" defaultChecked={userObject.approved}/>
                              <label style={{marginLeft: 5}} htmlFor="approved"> Approved</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <input type="checkbox" id="onboarded" name="onboarded" value="true" defaultChecked={userObject.onboarded}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="onboarded"> Onboarded</label>
                          </td>
                          <td>
                              <input type="checkbox" id="verified" name="verified" value="true" defaultChecked={userObject.verified}/>
                              <label style={{marginLeft: 5}} htmlFor="verified"> Verified</label>
                          </td>
                      </tr>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="notifications-checkbox" className="form-label">Notifications*</label>
                      <tr>
                          <td>
                              <input type="checkbox" id="emails" name="email_updates" value="true" defaultChecked={userObject.email_updates}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="emails"> Email Updates</label>
                          </td>
                          <td>
                              <input type="checkbox" id="texts" name="text_messages" value="true" defaultChecked={userObject.text_messages}/>
                              <label style={{marginLeft: 5}} htmlFor="texts"> Text Messages</label>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <input type="checkbox" id="alerts" name="recatch_alerts" value="true" defaultChecked={userObject.recatch_alerts}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="alerts"> Recatch Alerts</label>
                          </td>
                          <td>
                              <input type="checkbox" id="pushes" name="push_notifications" value="true" defaultChecked={userObject.notifications}/>
                              <label style={{marginLeft: 5}} htmlFor="pushes"> Push Notifications</label>
                          </td>
                      </tr>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="notifications-checkbox" className="form-label">Persmissions*</label>
                      <tr>
                          <td>
                              <input type="checkbox" id="admin" name="admin_access" value="true" defaultChecked={userObject.admin_user}/>
                              <label style={{marginRight: 15, marginLeft: 5}} htmlFor="admin"> Admin Access</label>
                          </td>
                          <td>
                              <input type="checkbox" id="partner" name="partner_access" value="true" defaultChecked={userObject.partner_user}/>
                              <label style={{marginLeft: 5}} htmlFor="partner"> Partner Access</label>
                          </td>
                      </tr>
                  </div>
                  
              </ModalBody>
              <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                      <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                  </div>
              </ModalFooter>
          </form>
          }
          
          <ModalBody>
              <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
          </ModalBody>
      </Modal>
      
      {/* Disable Modal */}
      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
      
          {!isDeleting &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Disable User's Account</h4>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to disable this user?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={disableUser}>Disable</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeleting}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Activate Modal */}
      <Modal isOpen={activateModal} toggle={() => { toggleActivateModal(); }} className="modal zoomIn" id="activateModal" centered >
      
          {!isActivating &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Activate User's Account</h4>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to activate this user?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleActivateModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={activateUser}>Activate</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isActivating}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
};

export default UserDetails;
