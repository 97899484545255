import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import { ThreeCircles } from 'react-loader-spinner'
import Flatpickr from "react-flatpickr";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const Onboardings = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allOnboardings, setAllOnboardings] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateOnboardingId, setUpdateOnboardingId] = useState('');
    const [updateOnboardingIndex, setUpdateOnboardingIndex] = useState(null);
    const [deleteOnboardingId, setDeleteOnboardingId] = useState('');
    const [deleteOnboardingIndex, setDeleteOnboardingIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    
    document.title = "Onboarding Q&A | Fish-Trak - Internal Management Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }
    
    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(onboardingId, onboardingIndex) {
        
        if(deleteModal) {
            setDeleteOnboardingId('');
            setDeleteOnboardingIndex(null);
        }
        else {
            setDeleteOnboardingId(onboardingId);
            setDeleteOnboardingIndex(onboardingIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const deleteOnboarding = async () => {
        
        setIsDeleting(true);
                
        //Delete onboarding from database
        const userRef = firebase.firestore().collection('users').doc(allOnboardings[deleteOnboardingIndex].user.id);
        const onboardingRef = firebase.firestore().collection('onboarding').doc(deleteOnboardingId);  
        onboardingRef
          .delete()
          .then(() => {

            userRef
                .update({
                    onboarded: false,
                    updated_at: serverTimestamp(),
                  })
                .then((userResponse) => {
                  fetchOnboardings();
                  alert('The onboarding answers have been deleted successfully!');
                  setDeleteOnboardingId('');
                  setDeleteOnboardingIndex(null);
                })
                .catch((error) => {
                  setIsDeleting(false);
                  alert('Could not update user onboarding status. Please try again.');
                });  

          })
          .catch((error) => {
            setIsDeleting(false);
            alert('Could not delete onboarding entry. Please try again.');
          });
    };
    
    const createList = () => {
        const onboardingsListOptions = {
             valueNames: ['name', 'user_email', 'created', 'completed'],
             pagination: true,
             indexAsync: true,
             searchColumns: ['name', 'user_email'],
             page: 10,
         };
         
         new List('onboardings-list', onboardingsListOptions);
    };
    
    const fetchOnboardings = async () => {
                  
     firebase
     .firestore()
     .collection('onboarding')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newOnboardingsList = []
              querySnapshot.forEach(doc => {
                  const onboardingItem = doc.data();
                  onboardingItem.id = doc.id;

                  onboardingItem.user_link = 'user/details/' + onboardingItem.user.id; 
                  onboardingItem.name = onboardingItem.user_first_name + ' ' + onboardingItem.user_last_name;
                  onboardingItem.link = 'onboarding/details/' + onboardingItem.id;                  
                                                                       
                  const createdAt = onboardingItem && onboardingItem.created_at && onboardingItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    onboardingItem.created = createdAt;
                    const updatedAt = onboardingItem && onboardingItem.updated_at && onboardingItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    onboardingItem.updated = updatedAt;
                    
                    newOnboardingsList.push(onboardingItem);                   
                  
              });
              setAllOnboardings(newOnboardingsList);
              setIsUploading(false);
              setmodal_list(false);
              setDeleteModal(false);
              setIsUpdating(false);
              setIsDeleting(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      )
    };

    useEffect(() => {
         
         
        if(initialLoad) {
           setInitialLoad(false);
           fetchOnboardings();
         }
         
         if(fetchedData && (allOnboardings.length > 0)) {
            setFetchedData(false);
            createList();
          }        
        
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                
                                <div id="onboardings-list">
                                <CardHeader>
                                    <h4 className="card-title mb-0">Onboarding Q&A</h4>
                                </CardHeader>
                                <CardBody>
                                    
                                        {/* <Row className="g-4 mb-3">

                                            <Col className="col-sm">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Onboarding Answers..." />
                                                    </div>
                                            </Col>
                                        </Row> */}

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="onboardings-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            View
                                                        </th>                                                        
                                                        <th className="sort" data-sort="name">User Name</th>
                                                        <th className="sort" data-sort="user_email">User Email</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Submitted</th>
                                                        <th className="sort" data-sort="years-fishing">Years Fishing</th>
                                                        <th className="sort" data-sort="angling-platform">Angling Platform</th>
                                                        <th className="sort" data-sort="monthly-fishings">Monthly Fishings</th>
                                                        <th className="sort" data-sort="completed">Completed</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                {(allOnboardings.length > 0) && allOnboardings.map((onboarding, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <a className="btn btn-sm btn-success edit-item-btn" href={onboarding.link}><i className="mdi mdi-eye-outline"></i></a>
                                                        </th>
                                                        <td><a href={onboarding.user_link} className="name fw-medium link-primary">{onboarding.name}</a></td>
                                                        <td><a href={onboarding.user_link} className="user_email fw-medium link-primary">{onboarding.user_email}</a></td>
                                                        <td className="created">{onboarding.created}</td>
                                                        <td className="years-fishing">{!!onboarding.years_fishing ? onboarding.years_fishing : 0} Years</td>
                                                        <td className="angling-platform">{onboarding.angling_platform}</td>
                                                        <td className="monthly-fishings">{!!onboarding.monthly_fishing_times ? onboarding.monthly_fishing_times : 0} Times</td>
                                                        <td>
                                                            {onboarding.completed &&
                                                                <span className="completed badge badge-soft-success text-uppercase">{String(onboarding.completed)}</span>
                                                            }
                                                            {!onboarding.completed &&
                                                                <span className="completed badge badge-soft-danger text-uppercase">{String(onboarding.completed)}</span>
                                                            }
                                                            
                                                        </td>
                                                        <td className="action">
                                                            <div className="d-flex gap-2">
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteModal(onboarding.id, index)}><i className="mdi mdi-delete-outline"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                    
                                                </tbody>
                                            </table>}
                                            
                                            {allOnboardings.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Onboardings Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any onboardings.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    
                                </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>

            {/* Remove Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Delete User's Onboarding Answers</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to delete these onboarding answers?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteOnboarding}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default Onboardings;
