import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const TagDetails = () => {
  
  document.title = "Tag Details | Fish-Trak - Internal Management Dashboard";
  
  //On mount variables
  const { tagId } = useParams();
  const storage = getStorage();
  const navigate = useNavigate();
  const tagRef = firebase.firestore().collection('tags').doc(tagId);
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [fetchingCatches, setFetchingCatches] = useState(true);
  const [allTagPrograms, setAllTagPrograms] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);
  
  //Data variables
  const [tagObject, setTagObject] = useState({});
  const [userObject, setUserObject] = useState({});
  const [tagCatches, setTagCatches] = useState([]);
  
  //Action buttons
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeletingUnused, setIsDeletingUnused] = useState(false);
  const [isDeletingUsed, setIsDeletingUsed] = useState(false);
  const [isRestoringUnused, setIsRestoringUnused] = useState(false);
  const [isRestoringUsed, setIsRestoringUsed] = useState(false);
  
  const [editModal, setEditModal] = useState(false);
  function toggleEditModal(tagId, tagIndex) {
      setEditModal(!editModal);
  }
  
  const [deleteUsedModal, setDeleteUsedModal] = useState(false);
  function toggleDeleteUsedModal(tagId, tagIndex) {
      setDeleteUsedModal(!deleteUsedModal);
      
  }
  
  const [deleteUnusedModal, setDeleteUnusedModal] = useState(false);
  function toggleDeleteUnusedModal(tagId, tagIndex) {

      setDeleteUnusedModal(!deleteUnusedModal);
      
  }
  
  const [restoreUsedModal, setRestoreUsedModal] = useState(false);
  function toggleRestoreUsedModal(tagId, tagIndex) {
      setRestoreUsedModal(!restoreUsedModal);
      
  }
  
  const [restoreUnusedModal, setRestoreUnusedModal] = useState(false);
  function toggleRestoreUnusedModal(tagId, tagIndex) {
  
      setRestoreUnusedModal(!restoreUnusedModal);
      
  }
  
  //Get user details
  const getTagDetails = async () => {
    tagRef
    .get()
    .then((tagDoc) => {
      const tagData = tagDoc.data();
      tagData.id = tagDoc.id;
      tagData.program_id = tagData.tag_program.id;
      tagData.angler_id = tagData.first_angler.id;
      tagData.date = tagData && tagData.created_at && tagData.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});      
      setTagObject(tagData);
      setFetchingDetails(false);
      getUserDetails(tagData.first_angler.id);
      
      setIsUploading(false);
      setDeleteUsedModal(false);
      setDeleteUnusedModal(false);
      setIsDeletingUnused(false);
      setIsDeletingUsed(false);
      setRestoreUsedModal(false);
      setRestoreUnusedModal(false);
      setIsRestoringUnused(false);
      setIsRestoringUsed(false);
      setEditModal(false);
      setIsUpdating(false);
      
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get tag details. Please try again.');
    });  
  };
  
  //Get user details
  const getUserDetails = async (userId) => {
    firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then((userDoc) => {
      const userData = userDoc.data();
      userData.id = userDoc.id;
      const userLastName = !!userData.last_name ? userData.last_name : '';
      userData.full_name = userData.first_name + ' ' + userLastName;
      userData.link = '../../user/details/' + userData.id; 
      setUserObject(userData);
      setFetchingUser(false);
    })
    .catch((error) => {
      setFetchingUser(false);
      alert('Could not get user details. Please try again.');
    });  
  };
  
  //Get user catches
  const getTagCatches = async () => {
    firebase
     .firestore()
     .collection('catches')
     .where('tag', '==', tagRef)
     .orderBy('catch_date', 'desc')
     .onSnapshot(
          querySnapshot => {
              const catchesArray = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  catchItem.id = doc.id
                  
                  catchItem.angler = catchItem.user_name;
                  catchItem.user_link = '../../user/details/' + catchItem.user.id; 
                  catchItem.species = catchItem.species_name;
                  catchItem.species_link = '../../species/details/' + catchItem.fish_species.id; 
                  const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  catchItem.date = catchDate;
                  catchItem.link = '../../catch/details/' + catchItem.id;  
                  
                  catchesArray.push(catchItem);
              });
              setTagCatches(catchesArray);
              setFetchingCatches(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
  }
  
  const deleteUsedTag = () => {
      
      setIsDeletingUsed(true);
      
      const usersRef = firebase.firestore().collection('users');
      const catchesRef = firebase.firestore().collection('catches');
      
      //Get all catches using the same tag
      catchesRef
       .where('tag', '==', tagRef)
       .onSnapshot(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    
                    //For each catch decrement user catch/recatch count
                    const userData = {
                      updated_at: serverTimestamp(),
                    };
                    
                    if(catchItem.recatch) {                        
                      if(!catchItem.recatch_count || catchItem.recatch_count == 0 || catchItem.recatch_count == 1 || catchItem.recatch_count == '' || catchItem.recatch_count == null) {
                          userData['recatch_count'] = 0; 
                        }
                        else {
                            userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                        }
                    }  
                    else {
                        if(!catchItem.catch_count || catchItem.catch_count == 0 || catchItem.catch_count == 1 || catchItem.catch_count == '' || catchItem.catch_count == null) {
                          userData['catch_count'] = 0; 
                        }
                        else {
                            userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                        }
                    }
                    
                    usersRef
                    .doc(catchItem.user.id)
                    .update(userData)
                    .then((userResponse) => {
                        
                        //Delete the catch or recatch
                        catchesRef
                          .doc(doc.id)
                          .update({
                            updated_at: serverTimestamp(),
                            deleted: true
                          })
                          .then(() => {
                              
                              console.log('Deleted tag');
                             
                          })
                          .catch((error) => {
                            setIsDeletingUsed(false);
                            alert('Could not delete catch entry. Please try again.');
                          });
                        
                      
                    })
                    .catch((error) => {
                      setIsDeletingUsed(false);
                      alert('Could not update user status. Please try again.');
                    }); 
                    
                });
                
                tagRef
                  .update({
                    updated_at: serverTimestamp(),
                    deleted: true
                  })
                  .then(() => {
                      getTagDetails();
                      alert('The tag has been deleted successfully!');
                     
                  })
                  .catch((error) => {
                    setIsDeletingUsed(false);
                    alert('Could not delete fish tag entry. Please try again.');
                  });
                
            },
            error => {
                console.log(error);
            }
        );   
        
  }
  
  const restoreUsedTag = () => {
      
      setIsRestoringUsed(true);
      
      const usersRef = firebase.firestore().collection('users');
      const catchesRef = firebase.firestore().collection('catches');
      
      //Get all catches using the same tag
      catchesRef
       .where('tag', '==', tagRef)
       .onSnapshot(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    
                    //For each catch decrement user catch/recatch count
                    const userData = {
                      updated_at: serverTimestamp(),
                    };
                    
                    if(catchItem.recatch) {                        
                      userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                    }  
                    else {
                      userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                    }
                    
                    usersRef
                    .doc(catchItem.user.id)
                    .update(userData)
                    .then((userResponse) => {
                        
                        //Delete the catch or recatch
                        catchesRef
                          .doc(doc.id)
                          .update({
                            updated_at: serverTimestamp(),
                            deleted: false
                          })
                          .then(() => {
                              
                              console.log('Restored tag');
                             
                          })
                          .catch((error) => {
                            setIsRestoringUsed(false);
                            alert('Could not restore catch entry. Please try again.');
                          });
                        
                      
                    })
                    .catch((error) => {
                      setIsRestoringUsed(false);
                      alert('Could not update user status. Please try again.');
                    }); 
                    
                });
                
                tagRef
                  .update({
                    updated_at: serverTimestamp(),
                    deleted: false
                  })
                  .then(() => {
                      getTagDetails(); 
                      alert('The tag has been restored successfully!');
                  })
                  .catch((error) => {
                    setIsRestoringUsed(false);
                    alert('Could not restore fish tag entry. Please try again.');
                  });
                
            },
            error => {
                console.log(error);
            }
        );   
        
  }
  
  const submitUpdateForm = async (event) => {
              
      setIsUpdating(true);
      event.preventDefault();
      
      const programId = event.target.tag_program.value;
      const programIndex = event.target.tag_program.selectedIndex - 1;
      const userId = event.target.first_angler.value;
      const tagsRef = firebase.firestore().collection('tags');
               
      //Create array of catch data
      const tagData = {
        updated_at: serverTimestamp(),
      };
      
      if(event.target.tag_number.value != tagObject.tag_number) {
          tagData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
      }
      if(programId != tagObject.tag_program.id) {
          tagData['tag_program'] = firebase.firestore().collection('programs').doc(programId);
          tagData['program_image'] = allTagPrograms[programIndex].image;
          tagData['program_name'] = allTagPrograms[programIndex].name;
      }
      if(userId != tagObject.first_angler.id) {
          tagData['first_angler'] = firebase.firestore().collection('users').doc(userId);
      }
             
      //Update tag in firestore
      tagsRef
          .doc(tagObject.id)
          .update(tagData)
          .then((tagResponse) => {
            alert('The tag has been updated successfully!');
            getTagDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update tag entry. Please try again.');
          });  
  };
  
  const deleteUnusedTag = async () => {
      
      setIsDeletingUnused(true);
              
      //Delete tag from database
      const tagsRef = firebase.firestore().collection('tags').doc(tagId);  
      tagsRef
        .update({
          updated_at: serverTimestamp(),
          deleted: true
        })
        .then(() => {
          getTagDetails();
          alert('The fish tag had been deleted successfully!');
        })
        .catch((error) => {
          setIsDeletingUnused(false);
          alert('Could not delete tag entry. Please try again.');
        });
  };
  
  const restoreUnusedTag = async () => {
      
      setIsRestoringUnused(true);
              
      //Delete tag from database
      const tagsRef = firebase.firestore().collection('tags').doc(tagId);  
      tagsRef
        .update({
          updated_at: serverTimestamp(),
          deleted: false
        })
        .then(() => {
          getTagDetails(); 
          alert('The fish tag had been restored successfully!');
        })
        .catch((error) => {
          setIsRestoringUnused(false);
          alert('Could not restore tag entry. Please try again.');
        });
  };
  
  const fetchTagPrograms = async () => {
        
   firebase
   .firestore()
   .collection('programs')
   .orderBy('created_at', 'desc')
   .onSnapshot(
        querySnapshot => {
            const newProgramsList = []
            querySnapshot.forEach(doc => {
                const programItem = doc.data();
                programItem.id = doc.id;
                
                programItem.name = programItem.program_name;
                programItem.region = programItem.program_region;
                programItem.country = programItem.country_code;
                programItem.type = programItem.program_type;
                programItem.length = programItem.tag_length;
                programItem.image = programItem.image_url;
                
                const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  programItem.created = createdAt;
                  const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  programItem.updated = updatedAt;
                
                
                newProgramsList.push(programItem);
            });
            setAllTagPrograms(newProgramsList);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const fetchAllUsers = async () => {
         
    firebase
    .firestore()
    .collection('users')
    .orderBy('created_at', 'desc')
    .onSnapshot(
         querySnapshot => {
             const newUsersList = []
             querySnapshot.forEach(doc => {
                 const userItem = doc.data();
                 userItem.id = doc.id;
                 
                 userItem.name = userItem.first_name + ' ' + userItem.last_name;
                 userItem.email = userItem.email_address;
                 userItem.port = userItem.home_port;
                 userItem.count = userItem.catch_count;
                 
                 const createdAt = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                   userItem.created = createdAt;
                   const updatedAt = userItem && userItem.updated_at && userItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                   userItem.updated = updatedAt;
                 
                 newUsersList.push(userItem);
             });
             setAllUsersList(newUsersList);
             
         },
         error => {
             console.log(error)
         }
     )
   };
   
   const createList = () => {
                  
          const catchListOptions = {
              valueNames: ['image_url', 'name', 'angler', 'location_string', 'date', 'type'],
              pagination: true,
              indexAsync: true,
              searchColumns: ['name', 'angler', 'location_string'],
              page: 10
          };    
        
        new List('connected-catch-list', catchListOptions);
   };
  
  useEffect(() => {
    if(initialLoad) {
       setInitialLoad(false);
       if(tagId) { 
          getTagDetails();
          getTagCatches();
        }
        fetchTagPrograms();
         fetchAllUsers();
     }
     
     if(fetchedData && !fetchingUser && !fetchingDetails && tagObject && tagCatches && (tagCatches.length > 0)) {
       setFetchedData(false);
       createList();
     }  
  });
  
  return (
    <React.Fragment>
      <div className="page-content">
      
      
        <Container fluid>
                  
          <Row>
            <Col lg="6">
              <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Fish Tag Details</h4>
                </CardHeader>
              
                <CardBody>
                
                  <ThreeCircles
                  visible={fetchingDetails}
                  height="100"
                  width="100"
                  color="#4192C3"
                  ariaLabel="three-circles-loading"
                  wrapperClass="justify-content-center"
                  />
                
                  {!fetchingDetails &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={tagObject.program_image}
                        alt={tagObject.program_name}
                        className="avatar-xl rounded img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>#{tagObject.tag_number}</h5>
                        <p className="mb-1">{tagObject.program_name}</p>
                        <p className="mb-0">{tagObject.date}</p>
                        {tagObject && tagObject.used &&
                          <span className="badge badge-soft-success text-uppercase">Used</span>
                        }
                        {tagObject && !tagObject.used &&
                          <span className="badge badge-soft-danger text-uppercase">Unused</span>
                        }
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="edit">
                            <button className="btn btn-md btn-warning edit-item-btn" data-bs-toggle="modal" data-bs-target="#updateModal" onClick={() => toggleEditModal()}><i className="mdi mdi-square-edit-outline"></i></button>
                        </div>
                        {!tagObject.used && !tagObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteUnusedModal()}><i className="mdi mdi-delete-outline"></i></button>
                        </div>
                        }
                        {tagObject.used && !tagObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteUsedModal()}><i className="mdi mdi-delete-outline"></i></button>
                        </div>
                        }
                        
                        {!tagObject.used && tagObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#restoreUnusedModal" onClick={() => toggleRestoreUnusedModal()}><i className="mdi mdi-history"></i></button>
                        </div>
                        }
                        {tagObject.used && tagObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#restoreUsedModal" onClick={() => toggleRestoreUsedModal()}><i className="mdi mdi-history"></i></button>
                        </div>
                        }
                    </div>
                  </div>
                  }
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
              
                <CardHeader>
                    <h4 className="card-title mb-0">1st Angler Details</h4>
                </CardHeader>
              
                <CardBody>
                
                  <ThreeCircles
                  visible={fetchingUser}
                  height="100"
                  width="100"
                  color="#4192C3"
                  ariaLabel="three-circles-loading"
                  wrapperClass="justify-content-center"
                  />
                
                  {!fetchingUser &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={userObject.image_url}
                        alt={userObject.full_name}
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userObject.full_name}</h5>
                        <p className="mb-1">{userObject.email_address}</p>
                        <p className="mb-0">{userObject.mobile_number}</p>
                        <p className="mb-0">{userObject.home_port}</p>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="edit">
                            <a className="btn btn-md btn-success edit-item-btn" href={userObject.link}><i className="mdi mdi-eye-outline"></i></a>
                        </div>
                    </div>
                  </div>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">Tagged Catches</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-catch-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="connected-catch-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th data-sort="image_url">Image</th>
                                              <th className="sort" data-sort="name">Catch Name</th>
                                              <th className="sort" data-sort="angler">Angler Name</th>
                                              <th className="sort" data-sort="location_string">Catch Location</th>
                                              <th className="sort" data-sort="weight">Weight</th>
                                              <th className="sort" data-sort="length">Length</th>
                                              <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                              <th className="sort" data-sort="type">Type</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                          {tagCatches && (tagCatches.length > 0) && tagCatches.map((catchItem, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="image_url">
                                                  {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                              </td>
                                              <td className="name">{catchItem.name}</td>
                                              <td><a href={catchItem.user_link} className="angler fw-medium link-primary">{catchItem.angler}</a></td>
                                              <td className="location_string">{catchItem.location_string}</td>
                                              <td className="weight">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.weight}
                                                  {catchItem && catchItem.metric_system && <span> KG</span>}
                                                  {catchItem && !catchItem.metric_system && <span> LB</span>}
                                                </span>
                                              </td>
                                              <td className="length">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.length}
                                                  {catchItem && catchItem.metric_system && <span> CM</span>}
                                                  {catchItem && !catchItem.metric_system && <span> IN</span>}
                                                </span>
                                              </td>
                                              <td className="date">{catchItem.date}</td>
                                              <td className="type">
                                                  {catchItem && catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Recatch</span>
                                                  }
                                                  {catchItem && !catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                  }
                                              </td>
                                          </tr>
                                          ))}
                                          
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingCatches}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingCatches) && fetchedData && !fetchingDetails && !fetchingUser && (!tagCatches || tagCatches.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
          
                                          <h5 className="mt-2">No Catches Found!</h5>
                                          <p className="text-muted mb-0">We could not find any catches.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
          
        </Container>
      </div>
      
      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
          <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Tag Program </ModalHeader>
          
          {!isUpdating && tagObject &&
          <form className="tablelist-form" onSubmit={submitUpdateForm}>
              <ModalBody>
              
                  <div className="mb-3">
                      <label htmlFor="tagnumber-field" className="form-label">Tag Number*</label>
                      <input type="text" id="tagnumber-field" className="form-control" placeholder="#ABCDE1234567890" maxLength={15} required name="tag_number" defaultValue={tagObject.tag_number}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                      <select className="form-control" data-trigger id="program-field" name="tag_program" required defaultValue={tagObject.program_id}>
                          <option value="">Select Tag Program</option>
                          {allTagPrograms.map(({ name, id }, index) => <option value={id} key={index}>{name}</option>)}
                      </select>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="program-field" className="form-label">Assigned User*</label>
                      <select className="form-control" data-trigger required id="program-field" name="first_angler" defaultValue={tagObject.angler_id}>
                          <option value="">Select User Email</option>
                          {allUsersList.map(({ email, id }, index) => <option value={id} >{email}</option>)}
                      </select>
                  </div>
      
              </ModalBody>
              <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                      <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                  </div>
              </ModalFooter>
          </form>
          }
          
          <ModalBody>
              <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
          </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={deleteUnusedModal} toggle={() => { toggleDeleteUnusedModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
      
          {!isDeletingUnused &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Delete Un-Used Fish Tag</h4>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to delete this fish tag?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteUnusedModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteUnusedTag}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeletingUnused}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={deleteUsedModal} toggle={() => { toggleDeleteUsedModal(); }} className="modal zoomIn" id="deleteCatchModal" centered >
      
          {!isDeletingUsed &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Delete Used Fish Tag </h4><br/>
                              <h5 className="text-muted mx-4 mb-0">This will delete the tag along with connected catches or recatches!!!</h5><br/>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to delete this tag?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteUsedModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteUsedTag}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeletingUsed}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={restoreUnusedModal} toggle={() => { toggleRestoreUnusedModal(); }} className="modal zoomIn" id="restoreUnusedModal" centered >
      
          {!isRestoringUnused &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Restore Un-Used Fish Tag</h4>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to restore this tag?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreUnusedModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreUnusedTag}>Restore</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isRestoringUnused}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={restoreUsedModal} toggle={() => { toggleRestoreUsedModal(); }} className="modal zoomIn" id="restoreUsedModal" centered >
      
          {!isRestoringUsed &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Restore Used Fish Tag </h4><br/>
                              <h5 className="text-muted mx-4 mb-0">This will restore the tag along with connected catches or recatches!!!</h5><br/>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to restore this tag?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreUsedModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreUsedTag}>Restore</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isRestoringUsed}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
};

export default TagDetails;
