import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const ProgramDetails = () => {
  
  document.title = "Program Details | Fish-Trak - Internal Management Dashboard";
  
  //On mount variables
  const { programId } = useParams();
  const storage = getStorage();
  const navigate = useNavigate();
  const programRef = firebase.firestore().collection('programs').doc(programId);
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [fetchingTags, setFetchingTags] = useState(true);
  const [fetchingCatches, setFetchingCatches] = useState(true);
  const [fetchedData, setFetchedData] = useState(false);
  
  //Data variables
  const [programObject, setProgramObject] = useState({});
  const [programTags, setProgramTags] = useState([]);
  const [programCatches, setProgramCatches] = useState([]);
  
  //Action buttons
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  
  const [editModal, setEditModal] = useState(false);
  function toggleEditModal() {
      setEditModal(!editModal);
  }
  
  const [deleteModal, setDeleteModal] = useState(false);
  function toggleDeleteModal() {
      setDeleteModal(!deleteModal);
  }
  
  const [restoreModal, setRestoreModal] = useState(false);
  function toggleRestoreModal() {
      setRestoreModal(!restoreModal);
  }
  
  const deleteProgram = async () => {
      
      setIsDeleting(true);
              
      //Delete program from database
      programRef
        .update({
          updated_at: serverTimestamp(),
          deleted: true
        })
        .then(() => {
          alert('The program had been deleted successfully!');
          getProgramDetails(); 
        })
        .catch((error) => {
          setIsDeleting(false);
          alert('Could not delete program entry. Please try again.');
        });
  };
  
  const restoreProgram = async () => {
      
      setIsRestoring(true);
              
      //Restore species in database
      programRef
        .update({
          updated_at: serverTimestamp(),
            deleted: false
          })
        .then(() => {
          alert('The program had been restored successfully!');
          getProgramDetails();
        })
        .catch((error) => {
          setIsRestoring(false);
          alert('Could not restore program entry. Please try again.');
        });
  };
  
  const submitUpdateForm = async (event) => {
  
      setIsUploading(true);
      event.preventDefault();
      
      setIsUpdating(true);
      
      if(updatedImage) {
          updateImage(event);
      }
      else {
          updateProgram(event);
      }
  
  }
  
  const updateImage = async (event) => {
      
      event.preventDefault();
    
    const imageFileName = '/program-logos/' + event.target.program_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
         
    const storageRef = ref(storage, imageFileName);
  
    const uploadTask = uploadBytesResumable(storageRef, updatedImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setIsUpdating(false);
          alert('Could not update program image. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          updateProgram(event, downloadedURL);
        });
      }
    );
  };
  
  const updateProgram = async (event, downloadedURL) => {
      
    //Create object of program data
      const programData = {
        updated_at: serverTimestamp(),
      };
      
      if(event.target.program_name.value != programObject.program_name) {
          programData['program_name'] = event.target.program_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
      }        
      if(event.target.tag_length.value != programObject.tag_length) {
          programData['tag_length'] = event.target.tag_length.value.replace(/[^0-9]/g, '');
      }
      if(event.target.program_region.value != programObject.program_region) {
          programData['program_region'] = event.target.program_region.value.replace(/[^a-zA-Z'-,.&+ ]/g, '');
      }
      if(event.target.program_country.value != programObject.country_code) {
          programData['country_code'] = event.target.program_country.value;
      }
      if(event.target.program_type.value != programObject.program_type) {
          programData['program_type'] = event.target.program_type.value;
      }
      if(event.target.program_website.value != programObject.website_url) {
          programData['website_url'] = event.target.program_website.value;
      }
      
      if(event.target.email_address.value != programObject.email_address) {
          programData['email_address'] = event.target.email_address.value;
      }
      
      
      if(downloadedURL) {
          programData['image_url'] = downloadedURL;
      }
              
      //Update program data
      programRef
          .update(programData)
          .then((programResponse) => {
            alert('The program has been updated successfully!');
            getProgramDetails(); 
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update program entry. Please try again.');
          });  
  };
  
  //Get user details
  const getProgramDetails = async () => {
    programRef
    .get()
    .then((programDoc) => {
      const programData = programDoc.data();
      programData.id = programDoc.id;      
      setProgramObject(programData);
      setFetchingDetails(false);
      
      setEditModal(false);
      setIsUploading(false);
      setIsUpdating(false);
      setDeleteModal(false);
      setIsDeleting(false);
      setRestoreModal(false);
      setIsRestoring(false);
      setFetchedData(true);
      
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get program details. Please try again.');
    });  
  };
  
  //Get program tags
  const getProgramTags = async () => {
    firebase
     .firestore()
     .collection('tags')
     .where('tag_program', '==', programRef)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const tagsArray = []
              querySnapshot.forEach(doc => {
                  const tagItem = doc.data();
                  tagItem.id = doc.id;
                  
                  
                  const createdAt = tagItem && tagItem.created_at && tagItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.created = createdAt;
                  const updatedAt = tagItem && tagItem.updated_at && tagItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.updated = updatedAt;
                  tagItem.link = '../../tag/details/' + doc.id;  
                  tagItem.program_link = '../../program/details/' + tagItem.tag_program.id;  
                  
                  tagItem.angler = tagItem.first_angler.id;  
                  tagItem.angler_link = '../../user/details/' + tagItem.first_angler.id;  
                  
                  firebase
                  .firestore()
                  .collection('users')
                  .doc(tagItem.first_angler.id)
                  .get()
                  .then((userDoc) => {
                      const userData = userDoc.data();
                      userData.id = userDoc.id;
                      tagItem.user_data = userData;
                      tagItem.angler = userData.first_name + ' ' + (!!userData.last_name ? userData.last_name : '');
                  }); 
                  
                  tagsArray.push(tagItem);
              });
              setProgramTags(tagsArray);
              setFetchingTags(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
  }
  
  //Get user catches
  const getProgramCatches = async () => {
    firebase
     .firestore()
     .collection('catches')
     .where('tag_program', '==', programRef)
     .orderBy('catch_date', 'desc')
     .onSnapshot(
          querySnapshot => {
              const catchesArray = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  catchItem.id = doc.id
                  
                  catchItem.angler = catchItem.user_name;
                  catchItem.user_link = '../../user/details/' + catchItem.user.id; 
                  catchItem.species = catchItem.species_name;
                  catchItem.species_link = '../../species/details/' + catchItem.fish_species.id; 
                  const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  catchItem.date = catchDate;
                  catchItem.link = '../../catch/details/' + catchItem.id;  
                  catchItem.tag_link = '../../tag/details/' + catchItem.tag.id; 
                  
                  catchesArray.push(catchItem);
              });
              setProgramCatches(catchesArray);
              setFetchingCatches(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
  }
  
  const createTagsList = () => {
       
      // Existing List
       const tagsListOptions = {
           valueNames: ['tag_number', 'angler', 'created', 'updated', 'catch_count', 'status'],
           pagination: true,
           indexAsync: true,
           searchColumns: ['tag_number'],
           page: 10,
       }; 
       
       new List('connected-tags-list', tagsListOptions);
  };
  
  const createCatchesList = () => {
                  
          const catchListOptions = {
              valueNames: ['image_url', 'name', 'angler', 'location_string', 'date', 'type'],
              pagination: true,
              indexAsync: true,
              searchColumns: ['name', 'angler', 'location_string'],
              page: 10
          };    
        
        new List('connected-catch-list', catchListOptions);
   };
  
  useEffect(() => {
    if(initialLoad) {
       setInitialLoad(false);
       if(programId) { 
          getProgramDetails();
          getProgramTags();
          getProgramCatches();
        }
     }
     
     if(fetchedData && !fetchingDetails && !fetchingCatches && programObject && programCatches && (programCatches.length > 0) && programTags && (programTags.length > 0)) {
        setFetchedData(false);
        createTagsList();
        createCatchesList();
      }  
  });
  
  return (
    <React.Fragment>
      <div className="page-content">

        
        <Container fluid>
          
          
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Program Details</h4>
                </CardHeader>
              
                <CardBody>
                  <ThreeCircles
                  visible={fetchingDetails}
                  height="100"
                  width="100"
                  color="#4192C3"
                  ariaLabel="three-circles-loading"
                  wrapperClass="justify-content-center"
                  />
                
                  {!fetchingDetails &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={programObject.image_url}
                        alt={programObject.program_name}
                        className="avatar-xl rounded img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{programObject.program_name}</h5>
                        <p className="mb-1">Program Location: {programObject.program_region}, {programObject.country_code}</p>
                        <p className="mb-0">Program Type: {programObject.program_type}</p>
                        <p className="mb-0">Program Website: <a href={programObject.website_url} className="fw-medium link-primary">{programObject.website_url}</a></p>
                        <p className="mb-0">Tag Length: <span className="badge badge-soft-success text-uppercase">{programObject.tag_length}</span></p>
                        {programObject.email_address && <p className="mb-0">Alerts Email: {programObject.email_address}</p>}
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="edit">
                            <button className="btn btn-md btn-warning edit-item-btn" data-bs-toggle="modal" data-bs-target="#updateModal" onClick={() => toggleEditModal()}><i className="mdi mdi-square-edit-outline"></i></button>
                        </div>
                        {!programObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={() => toggleDeleteModal()}><i className="mdi mdi-delete-outline"></i></button>
                        </div>
                        }
                        {programObject.deleted &&
                        <div className="remove">
                            <button className="btn btn-md btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#restoreModal" onClick={() => toggleRestoreModal()}><i className="mdi mdi-history"></i></button>
                        </div>
                        }
                    </div>
                  </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">Program Tags</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-tags-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Tags..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="tags-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th className="sort" data-sort="tag_number">Fish Tag Number</th>
                                              <th className="sort" data-sort="angler">Angler Details</th>
                                              <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                              <th className="sort" data-sort="updated" data-default-order='desc'>Updated Date</th>
                                              <th className="sort" data-sort="catch_count">Catches</th>
                                              <th className="sort" data-sort="status">Status</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                      {programTags && (programTags.length > 0) && programTags.map((tag, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={tag.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="tag_number">#{tag.tag_number}</td>
                                              <td><a href={tag.angler_link} className="angler fw-medium link-primary">{tag.angler}</a></td>
                                              <td className="created">{tag.created}</td>
                                              <td className="updated">{tag.updated}</td>
                                              <td><span className="catch_count badge badge-soft-success text-uppercase">{tag.catch_count}</span></td>
                                              
                                              <td className="status">
                                              {tag.used &&
                                                  <span className="badge badge-soft-success text-uppercase">Used</span>
                                              }
                                              {!tag.used &&
                                                  <span className="badge badge-soft-danger text-uppercase">Un-Used</span>
                                              }
                                              </td>
                                          </tr>
                                      ))}
                                      
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingTags}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingTags) && fetchedData && !fetchingDetails && (!programTags || programTags.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
                                  
                                          <h5 className="mt-2">No Tags Found!</h5>
                                          <p className="text-muted mb-0">We could not find any tags.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">Program Catches</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-catch-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="connected-catch-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th data-sort="image_url">Image</th>
                                              <th className="sort" data-sort="name">Catch Name</th>
                                              <th className="sort" data-sort="angler">Angler Name</th>
                                              <th className="sort" data-sort="location_string">Catch Location</th>
                                              <th className="sort" data-sort="tag_number">Tag Number</th>
                                              <th className="sort" data-sort="weight">Weight</th>
                                              <th className="sort" data-sort="length">Length</th>
                                              <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                              <th className="sort" data-sort="type">Type</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                          {programCatches && (programCatches.length > 0) && programCatches.map((catchItem, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="image_url">
                                                  {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                              </td>
                                              <td className="name">{catchItem.name}</td>
                                              <td><a href={catchItem.user_link} className="angler fw-medium link-primary">{catchItem.angler}</a></td>
                                              <td className="location_string">{catchItem.location_string}</td>
                                              <td><a href={catchItem.tag_link} className="tag_number fw-medium link-primary">#{catchItem.tag_number}</a></td>
                                              <td className="weight">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.weight}
                                                  {catchItem && catchItem.metric_system && <span> KG</span>}
                                                  {catchItem && !catchItem.metric_system && <span> LB</span>}
                                                </span>
                                              </td>
                                              <td className="length">
                                                <span className="badge badge-soft-success text-uppercase">
                                                  {catchItem.length}
                                                  {catchItem && catchItem.metric_system && <span> CM</span>}
                                                  {catchItem && !catchItem.metric_system && <span> IN</span>}
                                                </span>
                                              </td>
                                              <td className="date">{catchItem.date}</td>
                                              <td className="type">
                                                  {catchItem && catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Recatch</span>
                                                  }
                                                  {catchItem && !catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                  }
                                              </td>
                                          </tr>
                                          ))}
                                          
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingCatches}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingCatches) && fetchedData && !fetchingDetails && (!programCatches || programCatches.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
          
                                          <h5 className="mt-2">No Catches Found!</h5>
                                          <p className="text-muted mb-0">We could not find any catches.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
        </Container>
      </div>
      
      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
          <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Tag Program </ModalHeader>
          
          {!isUpdating && programObject &&
          <form className="tablelist-form" onSubmit={submitUpdateForm}>
              <ModalBody>
                  <div className="mb-3">
                      <label htmlFor="name-field" className="form-label">Program Name*</label>
                      <input type="text" id="name-field" className="form-control" placeholder="Fish-Trak Tagging Program" required name="program_name" defaultValue={programObject.program_name}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="length-field" className="form-label">Tag Length*</label>
                      <input type="number" id="length-field" maxLength="2" className="form-control" placeholder="Max: 15" required name="tag_length" defaultValue={programObject.tag_length}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="image-field" className="form-label">Image File*</label>
                      <tr>
                      <td><img className="avatar-sm rounded" alt={programObject.program_name} src={programObject.image_url}/></td>
                      <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                      </tr>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="region-field" className="form-label">Program Region*</label>
                      <input type="text" id="region-field" className="form-control" placeholder="Kentucky" required name="program_region" defaultValue={programObject.program_region}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="country-field" className="form-label">Program Country*</label>
                      <select className="form-control" data-trigger id="country-field" required name="program_country" defaultValue={programObject.country_code}>
                          <option value="">Select Program Country</option>
                          <option value="USA">United States</option>
                          <option value="CAN">Canada</option>
                      </select>
                  </div>                        
                  
                  <div className="mb-3">
                      <label htmlFor="type-field" className="form-label">Program Type*</label>
                      <select className="form-control" data-trigger id="type-field" required name="program_type" defaultValue={programObject.program_type}>
                          <option value="">Select Program Type</option>
                          <option value="Private">Private</option>
                          <option value="Public">Public</option>
                          <option value="University">University</option>
                          <option value="Government">Government</option>
                          <option value="State">State</option>
                          <option value="Federal">Federal</option>
                      </select>
                  </div> 
                  
                  <div className="mb-3">
                      <label htmlFor="website-field" className="form-label">Program Website*</label>
                      <input type="text" id="website-field" className="form-control" placeholder="https://fish-trak.com" required name="program_website" defaultValue={programObject.website_url}/>
                  </div>  
                  
                  <div className="mb-3">
                      <label htmlFor="email-field" className="form-label">Notification Email</label>
                      <input type="text" id="email-field" className="form-control" placeholder="alerts@fish-trak.com" name="email_address" defaultValue={programObject.email_address}/>
                  </div>  
                   
              </ModalBody>
              <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                      <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                  </div>
              </ModalFooter>
          </form>
          }
          
          <ModalBody>
              <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
          </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
      
          {!isDeleting &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Delete Tag Program</h4>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to delete this program?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteProgram}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeleting}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Restore Modal */}
      <Modal isOpen={restoreModal} toggle={() => { toggleRestoreModal(); }} className="modal zoomIn" id="restoreModal" centered >
      
          {!isRestoring &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Restore Tag Program</h4>
                      <p className="text-muted mx-4 mb-0">Do you really want to restore this program?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreProgram}>Restore</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isRestoring}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
};

export default ProgramDetails;
