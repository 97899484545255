import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import { ThreeCircles } from 'react-loader-spinner'
import Flatpickr from "react-flatpickr";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const ActiveUsers = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeUsersList, setActiveUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateUserId, setUpdateUserId] = useState('');
    const [updateUserIndex, setUpdateUserIndex] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [deleteUserIndex, setDeleteUserIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    document.title = "Registrations | Fish-Trak - Internal Management Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(userId, userIndex) {
        
        if(deleteModal) {
            setDeleteUserId('');
            setDeleteUserIndex(null);
        }
        else {
            setDeleteUserId(userId);
            setDeleteUserIndex(userIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const disableUser = async () => {
        
        setIsDeleting(true);
                
        //Delete onboarding from database
        const userRef = firebase.firestore().collection('users').doc(activeUsersList[deleteUserIndex].id);
    
            userRef
                .update({
                    active: false
                  })
                .then((userResponse) => {
                  fetchActiveUsers();
                  alert('The user account has been disabled successfully!');
                  setDeleteUserId('');
                  setDeleteUserIndex(null);
                })
                .catch((error) => {
                  setIsDeleting(false);
                  alert('Could not disable user. Please try again.');
                });  
    };
    
    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(tagId, tagIndex) {
        
        if(editModal) {
            setUpdateUserId('');
            setUpdateUserIndex(null);
        }
        else {
            setUpdateUserId(tagId);
            setUpdateUserIndex(tagIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateUser(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        event.preventDefault();
                    
      const imageFileName = '/user-avatars/' + activeUsersList[updateUserIndex].id;
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update user avatar. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateUser(event, downloadedURL);
          });
        }
      );
    };
    
    const updateUser = async (event, downloadedURL) => {
                
      //Create object of program data
        const userData = {
          updated_at: serverTimestamp(),
        };
        
        //User information      
        if(event.target.first_name.value != activeUsersList[updateUserIndex].first_name) {
            userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }  
        if((activeUsersList[updateUserIndex].last_name) && (event.target.last_name.value != activeUsersList[updateUserIndex].last_name)) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        } 
        else if((!activeUsersList[updateUserIndex].last_name) || (activeUsersList[updateUserIndex].last_name == '') || (activeUsersList[updateUserIndex].last_name == null)) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }
        //Contact details
        if(event.target.email_address.value != activeUsersList[updateUserIndex].email_address) {
            userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
        }  
        if(event.target.mobile_number.value != activeUsersList[updateUserIndex].mobile_number) {
            userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
        }  
        //Location data
        if(event.target.home_port.value != activeUsersList[updateUserIndex].home_port) {
            userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
        } 
        if((event.target.home_port_lat.value != activeUsersList[updateUserIndex].home_port_geo.latitude) || (event.target.home_port_lng.value != activeUsersList[updateUserIndex].home_port_geo.longitude)) {
            userData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, '')))
        }
        if(event.target.user_country.value != activeUsersList[updateUserIndex].country_code) {
            userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
        }
       
        //Metric counts 
        if(event.target.catch_count.value != activeUsersList[updateUserIndex].catch_count) {
            userData['catch_count'] = !!event.target.catch_count.value ? event.target.catch_count.value.replace(/[^0-9]/g, '') : 0;
        } 
        if(event.target.recatch_count.value != activeUsersList[updateUserIndex].recatch_count) {
            userData['recatch_count'] = !!event.target.recatch_count.value ? event.target.recatch_count.value.replace(/[^0-9]/g, '') : 0;
        } 
        if(event.target.species_count.value != activeUsersList[updateUserIndex].species_count) {
            userData['species_count'] = !!event.target.species_count.value ? event.target.species_count.value.replace(/[^0-9]/g, '') : 0;
        }         
        //Account Status
        if(event.target.units.value != activeUsersList[updateUserIndex].metric_system) {
            userData['metric_system'] = JSON.parse(event.target.units.value);
        }
        //Account Status
        if(event.target.active.value != activeUsersList[updateUserIndex].active) {
            userData['active'] = event.target.active.checked;
        }
        if(event.target.approved.value != activeUsersList[updateUserIndex].approved) {
            userData['approved'] = event.target.approved.checked;
        }
        if(event.target.onboarded.value != activeUsersList[updateUserIndex].onboarded) {
            userData['onboarded'] = event.target.onboarded.checked;
        }
        if(event.target.verified.value != activeUsersList[updateUserIndex].verified) {
            userData['verified'] = event.target.verified.checked;
        }
        
        //Notifications
        if(event.target.email_updates.value != activeUsersList[updateUserIndex].email_updates) {
            userData['email_updates'] = event.target.email_updates.checked;
        }
        if(event.target.text_messages.value != activeUsersList[updateUserIndex].text_messages) {
            userData['text_messages'] = event.target.text_messages.checked;
        }
        if(event.target.recatch_alerts.value != activeUsersList[updateUserIndex].recatch_alerts) {
            userData['recatch_alerts'] = event.target.recatch_alerts.checked;
        }
        if(event.target.push_notifications.value != activeUsersList[updateUserIndex].push_notifications) {
            userData['push_notifications'] = event.target.push_notifications.checked;
        }
    
        //Persmissions
        if(event.target.admin_access.value != activeUsersList[updateUserIndex].admin_user) {
            userData['admin_user'] = event.target.admin_access.checked;
        }
        if(event.target.partner_access.checked != activeUsersList[updateUserIndex].partner_user) {
            userData['partner_user'] = event.target.partner_access.checked;
        }
        
        if(downloadedURL) {
            userData['image_url'] = downloadedURL;
        }
                
        //Create new catch in firestore
        const userRef = firebase.firestore().collection('users').doc(activeUsersList[updateUserIndex].id);
        userRef
            .update(userData)
            .then((userResponse) => {
              alert('The user has been updated successfully!');
              fetchActiveUsers(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update user entry. Please try again.');
            });  
    };
    
    const createList = () => {
                   
           const usersListOptions = {
               valueNames: ['image_url', 'name', 'email', 'port', 'created', 'updated', 'count', 'status'],
               pagination: true,
               indexAsync: true,
               searchColumns: ['name', 'email', 'port'],
               page: 10
           };    
         
         new List('active-users-list', usersListOptions);
    };
    
    const fetchActiveUsers =  () => {
          
     firebase
     .firestore()
     .collection('users')
     .where('active', '==', true)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newUsersList = []
              querySnapshot.forEach(doc => {
                  const userItem = doc.data();
                    userItem.id = doc.id;
                    
                    userItem.name = userItem.first_name + ' ' + userItem.last_name;
                    userItem.email = userItem.email_address;
                    userItem.port = userItem.home_port;
                    const createdAt = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                      userItem.created = createdAt;
                      const updatedAt = userItem && userItem.updated_at && userItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                      userItem.updated = updatedAt;
                    userItem.count = userItem.catch_count;
                    userItem.status = userItem.active;
                    userItem.link = 'user/details/' + userItem.id;    
                  
                  newUsersList.push(userItem);
              });
              setActiveUsersList(newUsersList);
              setEditModal(false);
              setIsUploading(false);
              setIsUpdating(false);
              setmodal_list(false);
              setDeleteModal(false);
              setIsDeleting(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      )
    };

    useEffect(() => {
         
        if(initialLoad) {
           setInitialLoad(false);
           fetchActiveUsers();
         }
         
        if(fetchedData && (activeUsersList.length > 0)) {
          setFetchedData(false);
          createList();
        }  
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Registrations</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Active Users</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="active-users-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" disabled onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add New User</Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Users..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="active-users-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image_url">Image</th>
                                                        <th className="sort" data-sort="name">User Name</th>
                                                        <th className="sort" data-sort="email">Email Address</th>
                                                        <th className="sort" data-sort="port">Home Port</th>
                                                        <th className="sort" data-sort="count">Catches</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                                        <th className="sort" data-sort="updated" data-default-order='desc'>Last Active</th>
                                                        <th className="sort" data-sort="status">Status</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form">
                                                
                                                    {(activeUsersList.length > 0) && activeUsersList.map((user, index) => ( 
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success edit-item-btn" href={user.link}><i className="mdi mdi-eye-outline"></i></a>
                                                            </th>
                                                            <td className="image_url">
                                                                {user.image_url &&<img className="avatar-sm rounded-circle" alt={user.name} src={user.image_url} />}
                                                            </td>
                                                            <td className="name">{user.name}</td>
                                                            <td className="email">{user.email}</td>
                                                            <td className="port">{user.port}</td>
                                                            <td className="count"><span className="badge badge-soft-success text-uppercase">{user.count}</span></td>
                                                            <td className="created">{user.created}</td>
                                                            <td className="updated">{user.updated}</td>
                                                            <td className="status"><span className="badge badge-soft-success text-uppercase">Active</span></td>
                          
                                                            <td className="action">
                                                                <div className="d-flex gap-2">
                                                                    <div className="edit">
                                                                        <button className="btn btn-sm btn-warning edit-item-btn"
                                                                            onClick={() => toggleEditModal(user.id, index)}
                                                                            data-bs-toggle="modal" data-bs-target="#showModal"><i className="mdi mdi-square-edit-outline"></i></button>
                                                                    </div>
                                                                    {user.active &&
                                                                    <div className="remove">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => toggleDeleteModal(user.id, index)} data-bs-toggle="modal" data-bs-target="#deleteRecordModal"><i className="mdi mdi-account-off-outline"></i></button>
                                                                    </div>
                                                                    }       
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                
                                                </tbody>
                                            </table>}
                                            
                                            {activeUsersList.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Users Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any active users.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit User </ModalHeader>
                
                {!isUpdating && activeUsersList[updateUserIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="firstname-field" className="form-label">First Name*</label>
                            <input type="text" id="firstname-field" name="first_name" className="form-control" placeholder="Richard" required defaultValue={activeUsersList[updateUserIndex].first_name}/>
                            </td>
                            <td>
                            <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                            <input type="text" id="lastname-field" className="form-control" name="last_name" placeholder="Ryan" required defaultValue={activeUsersList[updateUserIndex].last_name}/>
                            </td>
                        </tr></div>
                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Email Address*</label>
                            <input type="email" id="email-field" name="email_address" className="form-control" placeholder="rich@fish-trak.com" required defaultValue={activeUsersList[updateUserIndex].email_address}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="mobile-field" className="form-label">Mobile Number*</label>
                            <input type="tel" id="mobile-field" className="form-control" placeholder="+14151234567" name="mobile_number" required defaultValue={activeUsersList[updateUserIndex].mobile_number}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">User Avatar*</label>
                            <tr>
                            <td><img className="avatar-sm rounded-circle" alt={activeUsersList[updateUserIndex].name} src={activeUsersList[updateUserIndex].image_url}/></td>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="homeport-field" className="form-label">Home Port Location*</label>
                            <input type="text" id="homeport-field" className="form-control" name="home_port" placeholder="Toms River" required defaultValue={activeUsersList[updateUserIndex].home_port}/>
                        </div>
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="latitude-field" className="form-label">Home Port Latitude*</label>
                            <input type="number" id="latitude-field" className="form-control" name="home_port_lat" placeholder="Enter Latitude*" required defaultValue={activeUsersList[updateUserIndex].home_port_geo.latitude} />
                            </td>
                            <td>
                            <label htmlFor="longitude-field" className="form-label">Home Port Longitude*</label>
                            <input type="number" id="longitude-field" className="form-control" name="home_port_lng" placeholder="Enter Longitude*" required defaultValue={activeUsersList[updateUserIndex].home_port_geo.longitude} />
                            </td>
                        </tr>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">User Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="user_country" defaultValue={activeUsersList[updateUserIndex].country_code}>
                                <option value="">Select User Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>
                        
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="catch-field" className="form-label">Catch Count*</label>
                            <input type="number" name="catch_count" id="catch-field" className="form-control" placeholder="Enter Catch Count*" required defaultValue={activeUsersList[updateUserIndex].catch_count} />
                            </td>
                            <td>
                            <label htmlFor="recatch-field" className="form-label">Recatch Count*</label>
                            <input type="number" id="recatch-field" name="recatch_count" className="form-control" placeholder="Enter Recatch Count*" required defaultValue={activeUsersList[updateUserIndex].recatch_count} />
                            </td>
                            <td>
                            <label htmlFor="species-field" className="form-label">Species Count*</label>
                            <input type="number" id="species-field" className="form-control" name="species_count" placeholder="Enter Species Count*" required defaultValue={activeUsersList[updateUserIndex].species_count} />
                            </td>
                        </tr></div>
                        
                        <div className="mb-3">
                            <label htmlFor="units-radio" className="form-label">Units System*</label>
                            <tr>
                            <td>
                                <input type="radio" id="metric" name="units" value="true" defaultChecked={!activeUsersList[updateUserIndex].metric_system} />
                                <label style={{marginRight: 15, marginLeft: 5}} htmlFor="metric" className="form-label">Metric</label>
                            </td>
                            <td>
                                <input type="radio" id="imperial" name="units" value="false" defaultChecked={activeUsersList[updateUserIndex].metric_system} />
                                <label style={{marginLeft: 5}} htmlFor="imperial" className="form-label">Imperial</label>
                            </td>
                        </tr></div>
                        
                        <div className="mb-3">
                            <label htmlFor="notifications-checkbox" className="form-label">Account Status*</label>
                            <tr>
                                <td>
                                    <input type="checkbox" id="active" name="active" value="true" defaultChecked={activeUsersList[updateUserIndex].active}/>
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="active"> Activated</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="approved" name="approved" value="true" defaultChecked={activeUsersList[updateUserIndex].approved}/>
                                    <label style={{marginLeft: 5}} htmlFor="approved"> Approved</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" id="onboarded" name="onboarded" value="true" defaultChecked={activeUsersList[updateUserIndex].onboarded}/>
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="onboarded"> Onboarded</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="verified" name="verified" value="true" defaultChecked={activeUsersList[updateUserIndex].verified}/>
                                    <label style={{marginLeft: 5}} htmlFor="verified"> Verified</label>
                                </td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="notifications-checkbox" className="form-label">Notifications*</label>
                            <tr>
                                <td>
                                    <input type="checkbox" id="emails" name="email_updates" value="true" defaultChecked={activeUsersList[updateUserIndex].email_updates}/>
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="emails"> Email Updates</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="texts" name="text_messages" value="true" defaultChecked={activeUsersList[updateUserIndex].text_messages}/>
                                    <label style={{marginLeft: 5}} htmlFor="texts"> Text Messages</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" id="alerts" name="recatch_alerts" value="true" defaultChecked={activeUsersList[updateUserIndex].recatch_alerts}/>
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="alerts"> Recatch Alerts</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="pushes" name="push_notifications" value="true" defaultChecked={activeUsersList[updateUserIndex].notifications}/>
                                    <label style={{marginLeft: 5}} htmlFor="pushes"> Push Notifications</label>
                                </td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="notifications-checkbox" className="form-label">Persmissions*</label>
                            <tr>
                                <td>
                                    <input type="checkbox" id="admin" name="admin_access" value="true" defaultChecked={activeUsersList[updateUserIndex].admin_user}/>
                                    <label style={{marginRight: 15, marginLeft: 5}} htmlFor="admin"> Admin Access</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="partner" name="partner_access" value="true" defaultChecked={activeUsersList[updateUserIndex].partner_user}/>
                                    <label style={{marginLeft: 5}} htmlFor="partner"> Partner Access</label>
                                </td>
                            </tr>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add User </ModalHeader>
                <form className="tablelist-form">
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="firstname-field" className="form-label">First Name*</label>
                            <input type="text" id="firstname-field" className="form-control" placeholder="Richard" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastname-field" className="form-label">Last Name*</label>
                            <input type="text" id="lastname-field" className="form-control" placeholder="Ryan" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Email Address*</label>
                            <input type="email" id="email-field" className="form-control" placeholder="rich@fish-trak.com" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="homeport-field" className="form-label">Home Port*</label>
                            <input type="text" id="homeport-field" className="form-control" placeholder="Toms River" required />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Cancel</button>
                            <button type="submit" className="btn btn-success disabled" id="add-btn">Add User</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Disable Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Disable User's Account</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to disable this user?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={disableUser}>Disable</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default ActiveUsers;
