import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import { ThreeCircles } from 'react-loader-spinner'
import Flatpickr from "react-flatpickr";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const TagPrograms = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [programImage, setProgramImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateProgramId, setUpdateProgramId] = useState('');
    const [updateProgramIndex, setUpdateProgramIndex] = useState(null);
    const [deleteProgramId, setDeleteProgramId] = useState('');
    const [deleteProgramIndex, setDeleteProgramIndex] = useState(null);
    const [restoreProgramId, setRestoreProgramId] = useState('');
    const [restoreProgramIndex, setRestoreProgramIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    document.title = "Tag Programs | Fish-Trak - Internal Management Dashboard";

    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(programId, programIndex) {
        
        if(editModal) {
            setUpdateProgramId('');
            setUpdateProgramIndex(null);
        }
        else {
            setUpdateProgramId(programId);
            setUpdateProgramIndex(programIndex);
        }
        
        setEditModal(!editModal);
    }

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(programId, programIndex) {
        
        if(deleteModal) {
            setDeleteProgramId('');
            setDeleteProgramIndex(null);
        }
        else {
            setDeleteProgramId(programId);
            setDeleteProgramIndex(programIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const [restoreModal, setRestoreModal] = useState(false);
    function toggleRestoreModal(programId, programIndex) {
        
        if(deleteModal) {
            setRestoreProgramId('');
            setRestoreProgramIndex(null);
        }
        else {
            setRestoreProgramId(programId);
            setRestoreProgramIndex(programIndex);
        }
        
        setRestoreModal(!restoreModal);
        
    }
    
    const deleteProgram = async () => {
        
        setIsDeleting(true);
                
        //Delete species from database
        const programRef = firebase.firestore().collection('programs').doc(deleteProgramId);  
        programRef
          .update({
              updated_at: serverTimestamp(),
              deleted: true
            })
          .then(() => {
            fetchTagPrograms();
            alert('The program had been deleted successfully!');
            setDeleteProgramId('');
            setDeleteProgramIndex(null);
             
          })
          .catch((error) => {
            setIsDeleting(false);
            alert('Could not delete program entry. Please try again.');
          });
    };
    
    const restoreProgram = async () => {
        
        setIsRestoring(true);
                
        //Delete species from database
        const programRef = firebase.firestore().collection('programs').doc(restoreProgramId);  
        programRef
          .update({
              updated_at: serverTimestamp(),
              deleted: false
            })
          .then(() => {
            fetchTagPrograms();
            alert('The program had been restored successfully!');
            setRestoreProgramId('');
            setRestoreProgramIndex(null);
             
          })
          .catch((error) => {
            setIsRestoring(false);
            alert('Could not restore program entry. Please try again.');
          });
    };
    
    const createList = () => {
         
         // Existing List
          const programsListOptions = {
              valueNames: ['name', 'region', 'country', 'created', 'updated', 'type', 'length'],
              pagination: true,
              indexAsync: true,
              searchColumns: ['name'],
              page: 15,
          };
         
         new List('programs-list', programsListOptions);
    };
    
    const submitCreateForm = async (event) => {
        
        setIsUploading(true);
        event.preventDefault();
      
      const imageFileName = '/program-logos/' + event.target.program_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
      console.log(imageFileName);
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, programImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            createNewProgram(event, downloadedURL);
          });
        }
      );
    };
    
    const createNewProgram = async (event, downloadedURL) => {
                
      //Create array of catch data
        const programData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          image_url: downloadedURL,
          program_name: event.target.program_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, ''),
          tag_length: event.target.tag_length.value.replace(/[^0-9]/g, ''),
          program_region: event.target.program_region.value.replace(/[^a-zA-Z'-,.&+ ]/g, ''),
          country_code: event.target.program_country.value,
          program_type: event.target.program_type.value,
          website_url: event.target.program_website.value,
          email_address: event.target.email_address.value.replace(/[^0-9a-zA-Z.+@_-]/g, ''),
          deleted: false
        };
                
        //Create new catch in firestore
        const programRef = firebase.firestore().collection('programs');
        programRef
            .add(programData)
            .then((programResponse) => {
              alert('The new program has been created successfully!');
              fetchTagPrograms(); 
            })
            .catch((error) => {
              setIsUploading(false);
              alert('Could not create program entry. Please try again.');
            });  
    };
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateProgram(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        event.preventDefault();
      
      const imageFileName = '/program-logos/' + event.target.program_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
           
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update program image. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateProgram(event, downloadedURL);
          });
        }
      );
    };
    
    const updateProgram = async (event, downloadedURL) => {
        
      //Create object of program data
        const programData = {
          updated_at: serverTimestamp(),
        };
        
        if(event.target.program_name.value != allTagPrograms[updateProgramIndex].name) {
            programData['program_name'] = event.target.program_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }        
        if(event.target.tag_length.value != allTagPrograms[updateProgramIndex].length) {
            programData['tag_length'] = event.target.tag_length.value.replace(/[^0-9]/g, '');
        }
        if(event.target.program_region.value != allTagPrograms[updateProgramIndex].region) {
            programData['program_region'] = event.target.program_region.value.replace(/[^a-zA-Z'-,.&+ ]/g, '');
        }
        if(event.target.program_country.value != allTagPrograms[updateProgramIndex].country) {
            programData['country_code'] = event.target.program_country.value;
        }
        if(event.target.program_type.value != allTagPrograms[updateProgramIndex].type) {
            programData['program_type'] = event.target.program_type.value;
        }
        if(event.target.program_website.value != allTagPrograms[updateProgramIndex].website) {
            programData['website_url'] = event.target.program_website.value;
        }
        
        if(event.target.email_address.value != allTagPrograms[updateProgramIndex].email_address) {
              programData['email_address'] = event.target.email_address.value;
          }
        
        if(downloadedURL) {
            programData['image_url'] = downloadedURL;
        }
                
        //Create new catch in firestore
        const programRef = firebase.firestore().collection('programs').doc(allTagPrograms[updateProgramIndex].id);
        programRef
            .update(programData)
            .then((programResponse) => {
              alert('The program has been updated successfully!');
              fetchTagPrograms(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update program entry. Please try again.');
            });  
    };
    
    const fetchTagPrograms =  () => {
          
     firebase
     .firestore()
     .collection('programs')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newProgramsList = []
              querySnapshot.forEach(doc => {
                  const programItem = doc.data();
                  
                  const programId = doc.id;
                  
                  programItem.id = programId;
                  programItem.image = programItem.image_url;
                  programItem.name = programItem.program_name;
                  programItem.region = programItem.program_region;
                  programItem.country = programItem.country_code;
                  programItem.type = programItem.program_type;
                  programItem.website = programItem.website_url;
                  programItem.length = programItem.tag_length;
                  programItem.link = 'program/details/' + programId;    
                  
                  const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.created = createdAt;
                    const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.updated = updatedAt;
                  
                  
                  newProgramsList.push(programItem);
              });
              setAllTagPrograms(newProgramsList);
              setEditModal(false);
              setRestoreModal(false);
              setmodal_list(false);
              setIsUploading(false);
              setIsUpdating(false);
              setDeleteModal(false);
              setIsDeleting(false);
              setIsRestoring(false);
              setFetchedData(true);
          },
          error => {
              console.log(error)
          }
      )
    };

    useEffect(() => {

         
        if(initialLoad) {
           setInitialLoad(false);
           fetchTagPrograms();
         }
         
         if(fetchedData && (allTagPrograms.length > 0)) {
             setFetchedData(false);
             createList();
           }  
        
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Tag Programs</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Programs</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="programs-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add Program</Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Programs..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="programs-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image">Image</th>
                                                        <th className="sort" data-sort="name">Program Name</th>
                                                        <th className="sort" data-sort="region">Region</th>
                                                        <th className="sort" data-sort="country">Country</th>
                                                        <th className="sort" data-sort="type">Type</th>
                                                        <th className="sort" data-sort="length">Tag Length</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                {(allTagPrograms.length > 0) && allTagPrograms.map((program, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <a className="btn btn-sm btn-success edit-item-btn" href={program.link}><i className="mdi mdi-eye-outline"></i></a>
                                                        </th>
                                                        <td className="image">
                                                            {program.image &&<img className="avatar-sm rounded" alt={program.name} src={program.image} />}
                                                        </td>
                                                        <td className="name">{program.name}</td>
                                                        <td className="region">{program.region}</td>
                                                        <td className="country">{program.country}</td>
                                                        <td className="type">{program.type}</td>
                                                        <td className="length"><span className="badge badge-soft-success text-uppercase">{program.length}</span></td>
                                                        <td className="action">
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal" onClick={() => toggleEditModal(program.id, index)}><i className="mdi mdi-square-edit-outline"></i></button>
                                                                </div>
                                                                
                                                                {!program.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteModal(program.id, index)}><i className="mdi mdi-delete-outline"></i></button>
                                                                </div>
                                                                }
                                                                
                                                                {program.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#restoreRecordModal" onClick={() => toggleRestoreModal(program.id, index)}><i className="mdi mdi-history"></i></button>
                                                                </div>
                                                                }
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>}
                                            
                                            {allTagPrograms.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Tag Programs Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any tag programs.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Tag Program </ModalHeader>
                
                {!isUpdating && allTagPrograms[updateProgramIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Program Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Fish-Trak Tagging Program" required name="program_name" defaultValue={allTagPrograms[updateProgramIndex].name}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="length-field" className="form-label">Tag Length*</label>
                            <input type="number" id="length-field" maxLength="2" className="form-control" placeholder="Max: 15" required name="tag_length" defaultValue={allTagPrograms[updateProgramIndex].length}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <tr>
                            <td><img className="avatar-sm rounded" alt={allTagPrograms[updateProgramIndex].name} src={allTagPrograms[updateProgramIndex].image}/></td>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="region-field" className="form-label">Program Region*</label>
                            <input type="text" id="region-field" className="form-control" placeholder="Kentucky" required name="program_region" defaultValue={allTagPrograms[updateProgramIndex].region}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">Program Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="program_country" defaultValue={allTagPrograms[updateProgramIndex].country}>
                                <option value="">Select Program Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>                        
                        
                        <div className="mb-3">
                            <label htmlFor="type-field" className="form-label">Program Type*</label>
                            <select className="form-control" data-trigger id="type-field" required name="program_type" defaultValue={allTagPrograms[updateProgramIndex].type}>
                                <option value="">Select Program Type</option>
                                <option value="Private">Private</option>
                                <option value="Public">Public</option>
                                <option value="University">University</option>
                                <option value="Government">Government</option>
                                <option value="State">State</option>
                                <option value="Federal">Federal</option>
                            </select>
                        </div> 
                        
                        <div className="mb-3">
                            <label htmlFor="website-field" className="form-label">Program Website*</label>
                            <input type="text" id="website-field" className="form-control" placeholder="https://fish-trak.com" required name="program_website" defaultValue={allTagPrograms[updateProgramIndex].website}/>
                        </div>   
                        
                        <div className="mb-3">
                              <label htmlFor="email-field" className="form-label">Notification Email</label>
                              <input type="text" id="email-field" className="form-control" placeholder="alerts@fish-trak.com" name="email_address" defaultValue={allTagPrograms[updateProgramIndex].email_address}/>
                          </div> 
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Tag Program </ModalHeader>
                
                {!isUploading &&
                <form className="tablelist-form" onSubmit={submitCreateForm}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Program Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Fish-Trak Tagging Program" required name="program_name" />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="length-field" className="form-label">Tag Length*</label>
                            <input type="number" id="length-field" maxLength="2" className="form-control" placeholder="Max: 15" required name="tag_length" />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <input type="file" name="image_link" required id="image-field" className="form-control" onChange={(e) => { setProgramImage(e.target.files[0]) }} />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="region-field" className="form-label">Program Region*</label>
                            <input type="text" id="region-field" className="form-control" placeholder="Kentucky" required name="program_region" />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">Program Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="program_country" >
                                <option value="">Select Program Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>                        
                        
                        <div className="mb-3">
                            <label htmlFor="type-field" className="form-label">Program Type*</label>
                            <select className="form-control" data-trigger id="type-field" required name="program_type" >
                                <option value="">Select Program Type</option>
                                <option value="private">Private</option>
                                <option value="public">Public</option>
                                <option value="university">University</option>
                                <option value="government">Government</option>
                                <option value="state">State</option>
                                <option value="federal">Federal</option>
                            </select>
                        </div> 
                        
                        <div className="mb-3">
                            <label htmlFor="website-field" className="form-label">Program Website*</label>
                            <input type="text" id="website-field" className="form-control" placeholder="https://fish-trak.com" required name="program_website" />
                        </div>   
                        
                        <div className="mb-3">
                              <label htmlFor="email-field" className="form-label">Notification Email</label>
                              <input type="text" id="email-field" className="form-control" placeholder="alerts@fish-trak.com" name="email_address"/>
                          </div> 
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Tag</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUploading}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto;"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Delete Tag Program</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to delete this program?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteProgram}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Restore Modal */}
            <Modal isOpen={restoreModal} toggle={() => { toggleRestoreModal(); }} className="modal zoomIn" id="restoreRecordModal" centered >
            
                {!isRestoring &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Restore Tag Program</h4>
                            <p className="text-muted mx-4 mb-0">Do you really want to restore this program?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreProgram}>Restore</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isRestoring}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default TagPrograms;
